.live-event-channel-switch__container {
  cursor: pointer;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 40px;
  min-height: 40px;
  padding: 0 10px;
  background: #f4f4f4;
}

.live-event-channel-switch__button {
  border-color: blueviolet;
  height: 36px;
  width: 272px;
}

.str-chat.dark .live-event-channel-switch__container {
  box-shadow: 0px 1px 0px #2c3134, 0px 10px 12px rgba(0, 0, 0, 0.04);
}
