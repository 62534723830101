.livestream.str-chat .str-chat__list .str-chat__reverse-infinite-scroll {
  background: #fff;
  padding: 0px;
  width: 100%;
  height: calc(100vh - 66px);
}

.str-chat__thread .livestream.str-chat .str-chat__list .str-chat__reverse-infinite-scroll {
  background: #fff;
  padding: 0px;
  width: 100%;
  height: 100%;
}

.pinned-messages__container .str-chat__reverse-infinite-scroll {
  background: #fff;
  padding-top: 0px;
  width: 100%;
  height: calc(100vh - 90px);
}
.pinned-messages__container {
  background: #fff;
  padding-top: 0px;
  width: 100%;
  height: calc(100vh - 90px);
}

.pinned-messages__container .pin-icon {
  display: none;
}
.str-chat.dark .pinned-messages__container {
  background: #1a1a1a;
}
.str-chat.dark .pinned-messages__container .str-chat__reverse-infinite-scroll {
  background: #1a1a1a;
}
