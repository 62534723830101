html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100vh;
}

.main-container {
  display: flex;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.2);
  height: 100%;
}

.main-container-inner {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.main-container-inner__left {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
}

.str-chat-channel{
  width: 100%;
}

.str-chat__main-panel{
  padding-bottom: 70px;
}

/* ----------------------------------------- Message List ----------------------------------------- */

.main-container-inner__right {
  display: flex;
  min-width: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.str-chat__list {
  background-color: #fff;
}

.str-chat.dark .str-chat__list {
  background-color: #1a1c1e;
}
.livestream.str-chat .str-chat__list {
  padding: 12px 0 0 0;
}

.str-chat.livestream.dark .str-chat__reverse-infinite-scroll {
  background: #000000;
}

.str-chat.livestream.dark .str-chat__message-livestream--initial-message {
  background: #1a1c1e;
  box-shadow: inset 0px -1px 0px #1a1c1e;
}
.str-chat.livestream.dark .str-chat__li {
  background: #1a1c1e;
}

.livestream.str-chat.dark .str-chat__li:hover {
  background: #1a1c1e;
}
.livestream.dark.str-chat .str-chat__message-livestream:hover .str-chat.livestream.dark .str-chat__message-livestream--initial-message {
  background: #1a1c1e;
}
.livestream.str-chat.dark .str-chat__list .str-chat__reverse-infinite-scroll {
  background: #1a1c1e;
}

/* ----------------------------------------- Message Threads ----------------------------------------- */

.str-chat__thread {
  background-color: #f1faff;
  max-width: 100%;
}

.str-chat__thread-list {
  width: 100%;
}

.str-chat__thread-start {
  display: none;
}

.str-chat__message-livestream--initial-message {
  min-height: 79px;
  width: 100%;
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  background: #f1faff;
  box-shadow: inset 0px -1px 0px #bed5e4;
  margin-left: 0px;
  padding-left: 10px;
}
.str-chat__thread-list {
  background: #f1faff;
  padding-top: 0px;
}
.str-chat__thread-header {
  background-color: white !important;
  width: 100%;
  padding: 0 20px;
}
.str-chat__thread-header-details {
  font-family: Helvetica Neue, sans-serif;
  display: flex;
  flex-direction: row;
  width: 128px;
  justify-content: space-around;
  align-items: center;
}
.str-chat__thread-header-details strong {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */

  color: #333333;
}
.str-chat__thread-header-details small {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */
  color: rgba(44, 44, 48, 0.65);
}

.str-chat__thread-list {
  box-shadow: inset 0px -1px 0px #bed5e4;
}

.str-chat__square-button svg {
  fill: rgba(140, 176, 202, 0.65);
  transform: scale(1.5);
  cursor: pointer;
}

.livestream.str-chat.dark .str-chat__thread-header,
.str-chat.dark .str-chat__thread-header {
  background: #000000;
}

.str-chat.livestream.dark .str-chat__thread-header {
  background-color: #000000 !important ;
}

.str-chat.livestream.dark .str-chat__thread-header-details small {
  color: white;
}

.str-chat.livestream.dark .str-chat__thread-header-details strong {
  color: white;
}

.str-chat.dark .str-chat__thread-list {
  background: #000000;
}

/* ----------------------------------------- Message ----------------------------------------- */

.str-chat__message-livestream:hover {
  box-shadow: none;
  border: 0px;
  border-radius: 0px;
}
.livestream.str-chat .str-chat__li:hover {
  box-shadow: none;
  border: 0px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}
.str-chat__message-livestream:hover .str-chat__message-livestream-actions {
  background: #ffffff;
  border: 1px solid #bed5e4;
  box-sizing: border-box;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 80px;
}

.str-chat__message-livestream-time {
  display: none;
}

.str-chat__message-livestream--initial-message .str-chat__avatar {
  display: flex;
}

.livestream.str-chat .str-chat__li {
  width: 100%;
}

.str-chat__message-livestream-left {
  width: 51px;
  display: flex;
  justify-content: center;
}

.str-chat__message-livestream-left .str-chat__avatar {
  margin-left: 15px;
  margin-right: 6px;
}

.str-chat__message-livestream-right {
  width: 245px;
}

.str-chat__message-attachment--media {
  width: 245px;
}
.str-chat__message-livestream-author strong {
  font-weight: normal;
}

.str-chat__message-replies-count-button {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 100%;
  /* identical to box height, or 11px */
  color: #19a0ff;
}

.str-chat__message-replies-count-button:focus {
  outline: 0 !important;
}

.str-chat__message-livestream:hover {
  border: 1px solid rgba(0, 0, 0, 0.01);
}

.str-chat__message-livestream-left .str-chat__avatar {
  margin-top: 6px;
}

.str-chat__message-livestream:hover .str-chat__message-livestream-actions {
  display: none;
}

.str-chat__message-livestream-actions .str-chat__message-actions-box--open {
  display: none;
}

.new-actions {
  display: none;
  height: 0px;
}

.livestream.str-chat .str-chat__li:hover .new-actions {
  display: flex;
}

.livestream.str-chat .str-chat__li:hover .str-chat__message-livestream-actions {
  display: flex;
  background: #ffffff;
  border: 1px solid #bed5e4;
  box-sizing: border-box;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 80px;
  margin-right: 7px;
}
.new-actions .str-chat__message-simple__actions__action--options {
  display: flex;
}
.new-actions .str-chat__message-livestream {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: flex;
  width: 100%;
  margin: 0 0;
  padding: 10px 10px;
  border: 1px solid rgba(0, 0, 0, 0);
  position: relative;
}
.new-actions .str-chat__message-livestream-actions {
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);
  width: 141px;
  height: 24px;
  padding: 0 4px;
  position: absolute;
  top: -12px;
  right: -18px;
  border-radius: 24px;
  display: none;
  align-items: center;
  justify-content: space-between;
}
.str-chat__message-livestream-actions > span svg {
  fill: #000;
  opacity: 0.5;
}
.str-chat__message-livestream-actions {
  right: -18px;
}

/* ----------------------------------------- Thread Actions Box ----------------------------------------- */

.str-chat__typing-indicator {
  display: none;
}
.str-chat__li--bottom {
  margin: 0;
}

.str-chat .str-chat__list--thread .str-chat__message-actions-list button:first-child {
  display: none;
}

/* ----------------------------------------- Live Video Icon ----------------------------------------- */

.live-video-icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
