.str-chat.dark .live-event-message-input__input {
  background-color: #1a1c1e;
}

.live-event-message-input__input-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px 0 5px;
}

.live-event-footer__input{
  width: 100%;
}

.live-event-message-input__input {
  color: #A8D0DB;
  line-height: 150%;
  position: relative;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
}

.str-chat.dark .live-event-message-input__input {
  border: 1px solid #2c3134;
}

.live-event-message-input__input:focus-within {
}

.str-chat.dark .live-event-message-input__input:focus-within {
}

.rfu-dropzone {
  display: flex;
  flex-direction: column;
}

.rfu-image-upload-button {
  display: none;
}

.rfu-image-previewer {
  height: fit-content;
  width: fit-content;
  margin: 0;
}

.rfu-image-previewer__image {
  margin: 0;
}

.rfu-image-previewer__image .rfu-thumbnail__wrapper {
  width: 42px !important;
  height: 42px !important;
}

.rfu-image-previewer__image .rfu-thumbnail__wrapper .rfu-thumbnail__overlay svg,
.rfu-image-previewer__image .rfu-thumbnail__wrapper .rfu-icon-button svg {
  height: 15px;
  width: 15px;
}

.str-chat__textarea {
  display: flex;
  border: 2px solid transparent;
}

.str-chat__textarea textarea{
  font-size: 16px;
}

.str-chat__textarea:focus-within {
  border: 2px solid #A8D0DB;
  border-radius: 8px;
}
.str-chat__textarea textarea {
  border: none;
  outline: none;
  box-shadow: none;
  background: #ffffff;
  max-width: 100%;
  border-radius: 8px;
}
.str-chat__textarea textarea:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.str-chat.dark .str-chat__textarea {
  background: #1a1c1e;
  border-radius: 8px;
}

.str-chat.dark .str-chat__textarea textarea {
  color: #26353F;
  background: #1a1c1e;
}

.str-chat__textarea textarea::placeholder {
  color: #26353F;
}
.live-event-message-input__wrapper .str-chat__input--emojipicker {
  z-index: 9;
}

.emoji-mart .emoji-mart-emoji:focus {
  outline: none;
}

.str-chat.dark .live-event-message-input__wrapper {
  background-color: #1a1c1e;
}
.str-chat.dark .str-chat__textarea textarea {
  background-color: #1a1c1e;
}
.str-chat.dark .str-chat__textarea {
  background-color: #1a1c1e;
}

div.rta__autocomplete.str-chat__emojisearch {
  z-index: 10;
  display: flex;
  background: white;
  color: black;
  line-height: 1.4;
  border-radius: 8px;
}
