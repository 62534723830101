.live-event-attendees__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.live-event-attendees__container-dark {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.live-event-attendees__title {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #2c2c30;
  margin: 15px 0 10px 15px;
}

.live-event-attendees__container-dark .live-event-attendees__title {
  color: white;
}

.live-event-attendees__moderators {
  display: flex;
  align-items: center;
  width: 100%;
  height: 126px;
  justify-content: space-evenly;
}

.live-event-attendees__moderator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 126px;
  box-shadow: 0px 3px 8px rgba(123, 160, 187, 0.2);
  border-radius: 8px;
  border: 1px solid rgba(123, 160, 187, 0.25);
}

.live-event-attendees__container-dark .live-event-attendees__moderator {
  background: black;
  box-shadow: 0px 3px 8px rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(123, 160, 187, 0.25);
}

.live-event-attendees__moderator-name {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #2c2c30;
  width: 100px;
  margin-top: 10px;
  text-align: center;
}

.live-event-attendees__container-dark .live-event-attendees__moderator-name {
  color: white;
}

.live-event-attendees__audience-member {
  display: flex;
  margin: 10px 15px 0 15px;
  justify-content: space-between;
  align-items: center;
}

.live-event-attendees__audience-member__left {
  display: flex;
  align-items: center;
}

.live-event-attendees__audience-member__left-name {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #2c2c30;
}

.live-event-attendees__container-dark .live-event-attendees__audience-member__left-name {
  color: white;
}

.live-event-attendees__audience-member__right {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #858688;
}

.live-event-attendees__footer {
  display: flex;
  margin-top: 52px;
  flex-direction: column;
  width: 100%;
}

.live-event-attendees__footer-count {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  text-align: center;
  color: #858688;
}
.live-event-attendees__footer-more {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  color: #189ffd;
}
