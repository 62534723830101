.live-event-footer__container {
  display: flex;
  position: fixed;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  background: #f4f4f4;
  bottom: 0;
  left: 0;
}

.live-event-footer__avatar{
  flex-shrink: 0;
  margin-right: 10px;
}

.str-chat.dark .live-event-footer__container {
  border-top: 1px solid #2c3134;
}
.live-event-footer__top {
  display: flex;
  flex-direction: row;
  flex: 0 0 55%;
  justify-content: space-around;
  padding: 10px 15px;
  align-items: center;
}
.live-event-footer__bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 0 0 45%;
}

.live-event-footer__bottom-left {
  display: flex;
  align-items: center;
}
.live-event-footer__bottom-badge {
  margin-right: 9px;
}

.str-chat.dark .live-event-footer__bottom-left {
  color: white;
}
.str-chat.dark .live-event-footer__bottom-left svg {
  fill: #000000;
}
